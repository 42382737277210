html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.App {
  position: relative;
  width: 100%;
  height: 100%;
  &-header {
    position: fixed;
    width: 100vw;
    height: 70px;
    top: 0;
    left: 0;
    text-align: center;
    background-color: #61dafb;
    color: #282c34;
    z-index: 1;
    h2 {
      @media (max-width:550px) {
        font-size: 20px;
      }
    }
  }
  &-main {
    position: absolute;
    top: 70px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 70px);
    padding: 30px;
    background-color: #282c34;
    color: #f3f3f3;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    @media (max-width:550px) {
      padding: 30px 10px;
    }
    table {
      width: 100%;
      thead {
        tr {
          background-color: #61dafb;
          color: #282c34;
          th {
            text-align: center;
            padding: 0 5px;
          }
        }
      }
      tbody {
        tr {
          background-color: #f3f3f3;
          color: #282c34;
          td {
            text-align: center;
            padding: 5px;
            &.green {
              color: green;
            }
            &.red {
              color: red;
            }
            input {
              background-color: #f3f3f3;
              border: 1px solid #282c34;
              height: 30px;
              width: 100%;
              font-size: 16px;
              outline: none;
              text-align: center;
              box-sizing: border-box;
              &[type="submit"] {
                width: 100%;
                background-color: #61dafb;
                outline: none;
                cursor: pointer;
              }
            }
          }
          &:nth-child(even) {
            background-color: lighten(#282c34, 50%);
            color: #f3f3f3;
          }
        }
      }
    }
  }
  &-loader {
    background-color: transparent !important;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    color: #61dafb !important;
  }
  &-footer {
    width: 100vw;
    height: 70px;
    text-align: center;
    background-color: #61dafb;
  }
}

@media only screen and (max-width: 550px)  {
  .App-main {
    table, thead, tbody, th, td, tr { 
      display: block; 
    }
    table {
      thead tr { 
        position: absolute;
        top: -9999px;
        left: -9999px;
      }
      tr { 
        border: 1px solid #ccc; 
      }    
      td { 
        text-align: right !important;
        border: none;
        border-bottom: 1px solid #eee; 
        position: relative;
        padding-left: 50%; 
        input[type="number"] {
          text-align: right !important;
          padding-right: 20px;
        }    
        &:before { 
          position: absolute;
          text-align: left;
          top: 6px;
          left: 6px;
          width: 45%; 
          padding-right: 10px; 
          white-space: nowrap;
        }
      }
      td:nth-of-type(1):before { content: "Nr."; }
      td:nth-of-type(2):before { content: "Name"; }
      td:nth-of-type(3):before { content: "Short name"; }
      td:nth-of-type(4):before { content: "$ value"; }
      td:nth-of-type(5):before { content: "Last 24h"; }
      td:nth-of-type(6):before { content: "Ammount you own"; }
      td:nth-of-type(7):before { content: "$ value of your coin"; }
    }
  }
}
